/* src/styles/Home.css */

/* Hero Section */
.hero {
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
  text-align: center;
}

.hero-overlay {
  background: rgba(0, 0, 0, 0.6); /* Transparent black overlay */
  padding: 2rem;
  border-radius: 8px;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #f0ad4e; /* Accent color */
}

.hero-description {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 2rem;
}

.hero-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #f0ad4e;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  transition: background 0.3s;
}

.hero-button:hover {
  background-color: #003366;
}

/* Why Choose Us Section */
.why-dmsl {
  padding: 3rem 1.5rem;
  background-color: #f8f8f8;
  text-align: center;
}

.why-dmsl-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.why-dmsl-image {
  max-width: 400px;
  border-radius: 8px;
}

.why-dmsl p {
  font-size: 1.1rem;
  line-height: 1.8;
  max-width: 600px;
  color: #333;
}

.highlights {
  padding: 2rem;
  background-color: #f8f8f8;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #003366;
}

.highlights-grid {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.highlight {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.highlight-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.highlight-content {
  padding: 1rem;
}

.highlight-content h3 {
  font-size: 1.5rem;
  color: #003366;
  margin: 0.5rem 0;
}

.highlight-content p {
  font-size: 1rem;
  color: #555;
}

.highlight-button {
  background-color: #003366; /* Same as the navbar color */
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.highlight-button:hover {
  background-color: #f0ad4e;
}

/* Quick Contact Section */
.quick-contact {
  padding: 3rem 1.5rem;
  background-color: #f8f8f8;
  text-align: center;
}

.contact-form {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form input,
.contact-form textarea {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.contact-form button {
  padding: 0.75rem;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #f0ad4e;
}
.it-services-info {
  margin-top: 2rem;
  font-size: 1rem;
  color: #003366;
  text-align: center;
  font-weight: bold;
  background-color: #f0f8ff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}




