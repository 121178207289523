/* src/styles/StudyAbroad.css */

.study-abroad {
    padding: 2rem;
    text-align: center;
    background-color: #f8f9fa;
  }
  
  .study-abroad .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #2c3e50;
  }
  
  .study-abroad .description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 2rem;
  }
  
  .destinations-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .destination-card {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .destination-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .destination-image {
    width: 100%;
    border-radius: 10px;
  }
  
  .destination-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;
    color: #34495e;
  }
  
  .destination-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .explore-button {
    background-color: #3498db;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .explore-button:hover {
    background-color: #1d6fa5;
  }
  