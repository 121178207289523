.carousel-section {
    padding: 2rem;
    background-color: #f5f5f5;
    text-align: center;
  }
  
  .carousel-title {
    font-size: 2.5rem;
    color: #003366;
    margin-bottom: 2rem;
  }
  
  .carousel-item {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    text-align: center; /* Ensure text is also centered */
  }
  
  .carousel-image {
    max-width: 40%;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 1rem auto; /* Center horizontally and add spacing */
    display: block; /* Ensures it's treated as a block element */
  }
  
  .carousel-content {
    margin-top: 1rem;
  }
  
  
  .carousel-content h3 {
    font-size: 1.8rem;
    color: #003366;
    margin: 0.5rem 0;
  }
  
  .carousel-content p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .slick-dots li button:before {
    color: #007bff;
  }
  
  .slick-dots li.slick-active button:before {
    color: #f0ad4e;
  }
  