.footer {
  background-color: #333;
  color: #ccc;
  padding: 2rem;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  margin: 1rem;
}

.footer-section h3 {
  color: #fff;
  margin-bottom: 1rem;
}

.footer-section p {
  margin: 0.5rem 0;
  line-height: 1.5;
}

.footer-logo {
  width: 150px;
  margin-top: 1rem;
}

.social-icons a {
  color: #ccc;
  margin: 0 0.5rem;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #f0ad4e;
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #444;
}

.footer-bottom nav a {
  color: #ccc;
  margin: 0 0.5rem;
  text-decoration: none;
}

.footer-bottom nav a:hover {
  color: #f0ad4e;
}
