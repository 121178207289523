.service-detail {
    padding: 2rem;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .section-title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .service-card {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
  }
  
  .service-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .service-title {
    font-size: 1.8rem;
    margin: 0.5rem 0;
    color: #444;
  }
  
  .service-description {
    font-size: 1rem;
    color: #666;
  }
  /* Videos Section */
.videos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
    padding: 1rem;
  }
  
  .video-card {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.2s ease-in-out;
  }
  
  .video-card:hover {
    transform: translateY(-5px);
  }
  
  .video-title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #444;
  }
  
  .video-player {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  .enroll-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .enroll-button:hover {
    background-color: #0056b3;
  }
  
  