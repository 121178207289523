/* src/styles/About.css */

.about {
  display: flex;
  align-items: center;
  padding: 2rem;
  background-color: #fff;
}

.about-image {
  width: 50%;
  border-radius: 8px;
}

.about-content {
  padding-left: 2rem;
}

.about-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.about-content p {
  font-size: 1.1rem;
  line-height: 1.6;
}
