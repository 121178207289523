/* src/styles/Contact.css */

.contact {
  padding: 2rem;
  background-color: #f9f9f9;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.contact-form-section {
  flex: 1;
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.contact-form .form-group {
  margin-bottom: 1rem;
}

.contact-form .form-group label {
  display: block;
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 0.5rem;
}

.contact-form .form-group input,
.contact-form .form-group textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact-form .form-group textarea {
  resize: none;
  height: 100px;
}

.submit-button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  color: #ffffff;
  background-color: #2c3e50;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #1a242f;
}

.contact-details {
  flex: 1;
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.details-title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.contact-details p {
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #555;
}

.contact-details a {
  color: #2c3e50;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}

.map {
  width: 100%;
  height: 300px;
  border: none;
  margin-top: 1rem;
  border-radius: 8px;
}
