/* Top Bar */
.top-bar {
  background-color: #003366;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensures vertical alignment */
  padding: 0.5rem 2rem;
  font-size: 0.9rem;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info span {
  margin-bottom: 0.3rem; /* Adds space between the phone and email info */
}

.social-icons {
  display: flex;
  gap: 1rem; /* Adds space between the social icons */
}

.social-icons a {
  color: white;
  font-size: 1.2rem; /* Increases icon size */
  text-decoration: none;
}

.social-icons a:hover {
  color: #f0ad4e; /* Changes color on hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .top-bar {
    flex-direction: column; /* Stacks items vertically on smaller screens */
    align-items: flex-start;
  }

  .contact-info {
    margin-bottom: 1rem;
  }

  .social-icons {
    justify-content: flex-start; /* Align icons to the left */
  }
}

/* Main Navbar */
.main-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1rem 2rem;
  position: sticky; /* Makes the navbar sticky */
  top: 0; /* Ensures it sticks to the top */
  z-index: 1000; /* Ensures it stays above other elements */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo img {
  height: 60px;
}

.company-name {
  text-align: center;
  flex-grow: 1; /* Ensures it stretches between logo and nav-links */
}

.company-name h1 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #003366;
  margin: 0;
}

.company-name p {
  font-size: 0.9rem;
  color: #f0ad4e;
  margin: 0;
}

/* Navbar Links */
.nav-links {
  list-style: none;
  display: flex;
  gap: 2rem;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.nav-links li {
  position: relative;
}

.nav-links a {
  color: #003366;
  text-decoration: none;
  font-weight: 600;
  padding: 0.5rem 0.3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav-links a:hover {
  color: #f0ad4e;
}

/* Hamburger Menu Icon */
.menu-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #003366;
}

/* Responsive Design */
@media (max-width: 768px) {
  .menu-icon {
    display: block; /* Show hamburger icon */
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    width: 100%;
    text-align: left;
  }

  .nav-links a {
    padding: 1rem;
    width: 100%;
    display: block;
    border-bottom: 1px solid #ddd;
  }

  .nav-links a:hover {
    background-color: #f0ad4e;
    color: white;
  }
}

/* Dropdown Styling (if needed for nested menus) */
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0.5rem 0;
  margin-top: 0;
  border-radius: 4px;
  z-index: 10;
}

.dropdown-menu li {
  white-space: nowrap;
}

.dropdown-menu a {
  padding: 0.5rem 1rem;
  color: #003366;
}

.dropdown-menu a:hover {
  background-color: #f0ad4e;
  color: white;
}
