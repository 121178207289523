/* General Services Section Styling */
.services {
  padding: 3rem 2rem;
  text-align: center;
  background-color: #f8f8f8;
}

.services h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}

/* Services Grid Layout */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin: 0 auto;
  max-width: 1200px; /* Center the grid */
}

/* Individual Service Card */
.service {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.service img {
  width: 100%;
  height: 180px; /* Uniform image height */
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

/* Service Title and Description */
.service h3 {
  font-size: 1.6rem;
  margin: 1rem 0;
  color: #333;
}

.service p {
  font-size: 1rem;
  color: #555;
  padding: 0 1rem;
  margin-bottom: 1.5rem;
}

/* Enroll Button */
.service .enroll-button {
  display: inline-block;
  padding: 0.7rem 1.2rem;
  margin-bottom: 1rem;
  background-color: #0077cc;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.service .enroll-button:hover {
  background-color: #005fa3;
}
