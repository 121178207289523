.more-info {
    padding: 2rem;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .hero-section {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .hero-image {
    width: 40%;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .hero-text {
    flex: 1;
  }
  
  .hero-text h1 {
    font-size: 2.5rem;
    color: #003366;
  }
  
  .hero-text p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
  }
  
  .details-section {
    margin-bottom: 2rem;
  }
  
  .details-section h2 {
    font-size: 2rem;
    color: #003366;
    margin-bottom: 1rem;
  }
  
  .details-section ul {
    list-style-type: disc;
    padding-left: 2rem;
  }
  
  .details-section ul li {
    margin-bottom: 0.8rem;
    font-size: 1.1rem;
    color: #555;
  }
  
  .call-to-action {
    text-align: center;
    background-color: #f8f8f8;
    padding: 2rem;
    border-radius: 8px;
  }
  
  .call-to-action h2 {
    font-size: 2rem;
    color: #003366;
    margin-bottom: 1rem;
  }
  
  .call-to-action p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 1.5rem;
  }
  
  .action-button {
    padding: 0.8rem 1.5rem;
    background-color: #003366;
    color: white;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .action-button:hover {
    background-color: #f0ad4e;
  }
  